import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  height: 5rem;
  padding: 0.5rem 1.5rem;
  
  @media(min-width: 800px) {
    padding: 1rem 10rem;
  }
`;

const Logo = styled(props => <Link {...props}/>)`
  color: rgba(230, 50, 50, 0.86);
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: auto;
  margin-bottom: auto;
  text-decoration: none;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
`;

const NavItem = styled(props => <Link {...props}/>)`
  color: rgba(88, 88, 88, 0.96);
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0px 0.6rem;
  text-decoration: none;
`;

const Navbar = () => {
  return (
    <Navigation>
      <Logo to={"/"}>AG</Logo>
      <Nav>
        <NavItem to={"/dyplomy"}>Dyplomy</NavItem>
        <NavItem to={"/o-mnie"}>O mnie</NavItem>
      </Nav>
    </Navigation>
  )
}

export default Navbar;