import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"

const StyledFooter = styled.footer`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 2.5rem 1.5rem;
  
  @media(min-width: 800px) {
    padding: 2rem 10rem;
  }
`;

const Container = styled.div`
  display: flex;
  display: row;
`;

const Infos = styled.div`
  width: 100%;
  color: rgba(88, 88, 88, 0.96);
  line-height: 1.4rem;
`;

const Copyright = styled.p`
  width: 100%;
  text-align: right;
  color: rgba(88, 88, 88, 0.96);
  margin: auto;
`;

const FooterRender = ({ data }) => {
  return(
    <StyledFooter>
      <Container>
        <Infos>
          <p>{ data.datoCmsFooter.emailSwitch ? `email: ${data.datoCmsFooter.email} ` : '' }</p>
          <p>{ data.datoCmsFooter.phoneNumberSwitch ? `tel: ${data.datoCmsFooter.phoneNumber}` : '' }</p>
        </Infos>
        <Copyright>&copy; Agnieszka Gajda. Wszelkie prawa zastrzeżone</Copyright>
      </Container>
    </StyledFooter>
  );
}

export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          datoCmsFooter {
            emailSwitch
            email
            phoneNumberSwitch
            phoneNumber
          }
        }
      `}
      render={data => <FooterRender data={data}/>}
    />
  )
};
